.header-title {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    font-family: ptserif;
    font-size: $font-large;
    text-align: center;
    color: $light-grey;
    background: $background-color;
    padding: 1rem;
    box-shadow: 0 .5rem .5rem rgba(0,0,0,.7);

    @include respond(tab-port) {
      font-size: 6rem;
    }
    @include respond(phone) {
      font-size: 3rem;
    }
  }
  