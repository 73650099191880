
.confessional-booth__text {
    font-family: roboto;
    border: none;
    color: $placeholder-text-color;
    font-size: $font-normal;
    padding: 2rem;
    width: 100%;
    height: 15rem;
    resize: none;
}

.confessional-booth__text.active {
    color: #000;
    font-size: $font-small;
    resize: none;
}

.confessional-booth__button {
    vertical-align: middle;
    color: $text-color;
    background: $background-color;
    border: none;
    font-family: roboto;
    font-size: $font-normal;
    width: 100%;
    margin: 2rem 0;
    padding: .5rem 0 1rem 0;
    cursor: pointer;
}

.confessional-booth__button:hover {
    color: #000;
    background: #fff;
}

.confessional-booth__capthca-warning {
    color: red;
    font-size: $font-small;
    margin: 1rem;
}
