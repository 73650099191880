.confession {
    display: flex;
    flex-direction: column;
    font-size: $font-small;
    margin-top: 5rem;
}

.confession--title {
    margin-bottom: 1rem;
}

.confession--text {
    margin-bottom: 1rem;
}

.confession--text::before {
    content:"“";
    position: relative;
    top: 3rem;
    font-size: $font-large;
    line-height: 1rem;
    padding-right: 1rem;
}

.confession--text::after {
    content:"”";
    position: relative;
    top: 4rem;
    left: 1rem;
    font-size: $font-large;
    line-height: 0rem;
}

.confession--forgiveness-container{
    display: flex;
    width: 100%;
    align-items: center;
}

.confession--forgiveness-counter {
    display: flex;
    justify-content: center;
    flex-grow: 1;

    @include respond(phone) {
        font-size: 1.4rem;
    }
}
.confession--forgiveness-counter::before {
    content: "";
    flex-grow: 1;
    height: 0;
    align-self: center;
    border-bottom: 2px solid #000;
    margin: 0 1rem 0 0;
}
.confession--forgiveness-counter::after {
    content: "";
    flex-grow: 1;
    height: 0;
    align-self: center;
    border-bottom: 2px solid #000;
    margin: 0 1rem 0 1rem;
}

.confession--forgiveness_counter_separator {
    margin: 0 1rem;
}

.confession--forgiveness_counter-active {
    white-space: pre;    
    color: $light-grey;
}

.confession--forgiveness_counter-inactive {
    white-space: pre;
}

.confession--button-container {
    position: relative;
    flex-grow: 0;
}

.confession--button {
    background: $button-two;
    font-family: roboto;
    font-size: $font-small-normal;
    cursor: pointer;
    border: none;
}

.confession--button__forgive {
    // dimensions are explicit for clipping tricks
    width: 142px;
    height: 50px;
    clip-path: polygon(0 0, 142px 0, 120px 50px, 0 50px);
    margin-right: -1rem;

    @include respond(phone) {
        width: 72px;
        height: 25px;
        clip-path: polygon(0 0, 72px 0, 60px 25px, 0 25px);

    }
}

.confession--button-container::after {
    // this functions as border for forgive button
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: -1;
    background: #fff;
    width: 144px;
    height: 52px;
    clip-path: polygon(0 0, 144px 0, 122px 52px, 0 52px);

    @include respond(phone) {
        width: 74px;
        height: 27px;
        clip-path: polygon(0 0, 74px 0, 62px 27px, 0 27px);
    }
}

.confession--button__not {
    width: 80px;
    height: 50px;
    clip-path: polygon(22px 0, 80px 0, 80px 50px, 0 50px);

    @include respond(phone) {
        width: 40px;
        height: 25px;
        clip-path: polygon(12px 0, 40px 0, 40px 25px, 0 25px);
    }
}

.confession--button-text__forgive {
    position: relative;
    top: -2px;
    left: -4px;

    @include respond(phone) {
        font-size: 1.5rem;
        top: -8px;
    }
}

.confession--button-text__not {
    position: relative;
    top: -2px;
    left: 4px;

    @include respond(phone) {
        font-size: 1.5rem;
        top: -8px;
    }
}

.confession--button-container::before {
    // this functions as border for not button
    content: "";
    position: absolute;
    top: -1px;
    right: -1px;
    z-index: -1;
    background: #fff;
    width: 82px;
    height: 52px;
    clip-path: polygon(22px 0, 82px 0, 82px 52px, 0 52px);

    @include respond(phone) {
        width: 42px;
        height: 27px;
        clip-path: polygon(12px 0, 42px 0, 42px 27px, 0 27px);
    }
}

.confession--button:hover {
    color: $button-two;
    background: $text-color;
}
