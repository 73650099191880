@font-face {
    font-family: 'ptserif';
    src: url(../../ptserif.ttf);
  
    font-family: 'roboto';
    src: url(../../roboto.ttf);
  }

// sizes

$font-small:  2rem;
$font-small-normal: 3rem;
$font-normal: 4rem;
$font-large:  8rem;
